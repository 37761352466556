import React, { useEffect, useContext, useState } from 'react';
import { graphql } from 'gatsby';
import Layout from 'components/layout';
import SEO from 'components/seo';
import Components from 'components/CmsComponents/';
import { Logo } from 'components/CmsComponents/Logo';
import { CarouselBanner } from 'components/CmsComponents/CarouselBanner';
import { DoctorContext } from 'context/doctor';
import { HeaderContext } from 'context/header';
import { createDoctorData } from 'utils/createDoctorData';

const MesesColoridos = ({
  data: {
    gcms: { mesesColorids, specialty },
    doctorsApi,
  },
  hideHeader,
}) => {
  const [isDesktop, setDesktop] = useState(true);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setDesktop(window.innerWidth > 1024);
    }
  }, []);

  const {
    setMainMarginTopLaptop,
    setMainMarginTopMobile,
    showAlertPostFeriado,
    isVisibleNovoSite,
  } = useContext(HeaderContext);

  const page = mesesColorids?.[0];
  const logoContent = page?.logo;
  const carouselBannerContent = page?.carouselBanner;
  const components = page?.components ?? [];
  const specialtyDoctors = page?.specialty?.doctor || specialty?.doctor;
  const apiDoctors = doctorsApi?.doctors;
  const header = page?.headerDS ?? [];
  const headerPersonalizado = page?.headerPersonalizado;

  const { setRenderList, setDoctorsList, setDoctorsOncology } = useContext(
    DoctorContext
  );

  if (specialtyDoctors) {
    const formattedDoctors = specialtyDoctors
      .map(doctor =>
        createDoctorData({
          doctorCMS: doctor,
          doctorsAPI: apiDoctors,
        })
      )
      ?.filter(e => e)
      .sort(
        (a, b) =>
          (a.name.trim().toLowerCase() > b.name.trim().toLowerCase() && 1) || -1
      )
      .sort((a, b) => b.employee - a.employee);

    useEffect(() => {
      setDoctorsOncology(formattedDoctors);
      setDoctorsList(formattedDoctors);
      setRenderList('all');
    }, []);
  }

  const dataSeo = [
    {
      seo: {
        metaTitle: page?.seo?.metaTitle ?? '',
        metaDescription: page?.seo?.metaDescription ?? '',
      },
    },
  ];

  useEffect(() => {
    if (showAlertPostFeriado || isVisibleNovoSite) {
      setMainMarginTopLaptop('9.075rem');
      setMainMarginTopMobile('7.875rem');
    } else {
      setMainMarginTopLaptop('7.063rem');
      setMainMarginTopMobile('0rem');
    }
  }, [showAlertPostFeriado, isVisibleNovoSite]);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setDesktop(window.innerWidth > 1024);
    }
  }, []);

  return (
    <Layout hideHeader={headerPersonalizado}>
      {page?.headerPersonalizado && (
        <>{!hideHeader ? header && Components(header) : ''}</>
      )}
      <SEO dataSeo={dataSeo} />
      {logoContent && <Logo content={logoContent} />}
      {carouselBannerContent && (
        <CarouselBanner content={carouselBannerContent} />
      )}
      {components.length >= 1 &&
        components?.map(component => {
          return Components(component);
        })}
    </Layout>
  );
};

export const MesesColoridosPageQuery = graphql`
  query MesesColoridosPageQuery($id: ID, $slug: String) {
    doctorsApi {
      doctors {
        id
        name
        gender
        crm
        email
        phone
        phonecommercial
        specialty
        employee
        estab
        state
      }
    }
    gcms {
      doctors(first: 1000) {
        active
        id
        id_api
      }
      specialty(locales: [pt_BR, en], where: { slug: $slug }) {
        doctor(first: 1000) {
          id
          id_api
          active
          assignment
          name
          crm
          photo {
            handle
            url
            height
            width
          }
          phonecommercial
          state
          employee
          specialties {
            id
            name
          }
        }
      }
      mesesColorids(locales: [pt_BR, en], where: { id: $id }) {
        id
        title
        slug
        seo {
          id
          metaTitle
          metaDescription
          noIndex
        }
        headerPersonalizado
        headerDS {
          ... on GraphCMS_ComponentDsHeader {
            id
            localizations(includeCurrent: true) {
              locale
              topBarLinks
              menuLinks
              imageLogo {
                width
                height
                handle
                url
              }
            }
          }
        }
        logo(locales: [pt_BR, en]) {
          id
          name
          logoData {
            id
            image {
              url
            }
          }
        }
        carouselBanner(locales: [pt_BR, en]) {
          id
          name
          activeBreaknews
          breaknews {
            id
            name
            type
            singletexts
            markdown
          }
          bannersData {
            id
            buttonLink
            buttonText
            backgroundColor {
              hex
            }
            bannerImage {
              url
            }
            bannerImageMobile {
              url
            }
            subtitle
            title
            imageLink
          }
        }
        components(locales: [pt_BR, en]) {
          __typename
          ... on GraphCMS_HighlightContent {
            id
            name
            type
            backgroundColorInitial {
              hex
            }
            backgroundColorFinal {
              hex
            }
            title
            colorText {
              hex
            }
            customTitle
            description
            images {
              handle
              width
              height
              url
            }
            fragments {
              id
              name
              type
              markdown
            }
          }
          ... on GraphCMS_SimpleContent {
            id
            name
            type
            backgroundColor {
              hex
            }
            title
            fragments {
              id
              name
              type
              markdown
              datafragment
              singletexts
              assetpicker {
                handle
                width
                height
                url
              }
            }
          }
          ... on GraphCMS_OurSpecialties {
            id
            name
            title
            customFragment {
              id
              name
              type
              markdown
              datafragment
              singletexts
              localizations {
                markdown
              }
            }
          }
          ... on GraphCMS_VideoGalleryContent {
            id
            name
            type
            title
            headerLinkTitle
            headerLink
            description
            fragments {
              id
              name
              type
              markdown
            }
          }
          ... on GraphCMS_DSButtonContent {
            id
            title
            description
            buttons
          }
          ... on GraphCMS_HighlightFormLeadContent {
            id
            name
            type
            backgroundColorInitial {
              hex
            }
            backgroundColorFinal {
              hex
            }
            backgroundColorText {
              hex
            }
            title
            customTitle
            description
            referenceToSalesforce
            images {
              handle
              width
              height
              url
            }
            documentsForDownload {
              handle
              width
              height
              url
            }
            footnote
          }
          ... on GraphCMS_DoctorsSearch {
            id
            name
            location
            noSearch
          }
          ... on GraphCMS_AppDownloadBanner {
            id
            name
            title
            description
            imgLeft {
              handle
              width
              height
              url
            }
            linkImgLeft
            imgRight {
              handle
              width
              height
              url
            }
            linkImgRight
            imgCenter {
              handle
              width
              height
              url
            }
            textButton
            linkTextButton
          }
          ... on GraphCMS_ListPostContent {
            id
            name
            type
            title
            headerLinkTitle
            headerLink
            posts {
              id
              slugPost
              title
              topic
              author
              doctors(first: 1000, locales: [pt_BR, en]) {
                id
                id_api
                active
                assignment
                name
              }
              assetpicker {
                handle
                height
                width
                url
              }
              categories {
                tag
              }
            }
          }
          ... on GraphCMS_ListQAContent {
            id
            name
            title
            subTitle
            type
            isDesignSystem

            anchorTo
            faqLink
            backgroundColor {
              hex
            }
            qaData {
              ... on GraphCMS_CustomFragment {
                id
                name
                markdown
                singletexts
              }
              ... on GraphCMS_Qa {
                id
                autor
                pergunta
                titulo
                doctors(first: 1000) {
                  id
                  id_api
                  active
                  assignment
                  name
                }
              }
            }
          }
          ... on GraphCMS_ImageCircle {
            id
            name
            backgroundColor {
              hex
            }
            title
            description
            imagesData {
              id
              image {
                url
              }
              linkCircle
              titleImage
              descriptionImage
            }
          }
          ... on GraphCMS_CardEffect {
            id
            name
            title
            description
            cardsData {
              firstImage {
                url
              }
              secondImage {
                url
              }
              linkSecondImage
            }
          }
          ... on GraphCMS_ContentTab {
            id
            name
            title
            tabsData {
              tabTitle
              image {
                url
              }
              imageMobile {
                url
              }
            }
          }
          ... on GraphCMS_CardDownload {
            id
            name
            backgroundColor {
              hex
            }
            title
            fragments {
              id
              name
              singletexts
              datafragment
              markdown
              localizations {
                singletexts
                markdown
                datafragment
              }
            }
          }
          ... on GraphCMS_BannerFullImage {
            id
            name
            bannersData {
              id
              bannerImage {
                url
              }
              bannerImageMobile {
                url
              }
              imageLink
            }
          }
          ... on GraphCMS_CarouselGalleryContent {
            id
            name
            type
            title
            backgroundColor {
              hex
            }
            images {
              handle
              width
              height
              url
            }
          }
          ... on GraphCMS_FormLeadPersonalizado {
            id
            name
            backgroundColor {
              hex
            }
            title
            description
            subtitle
            textNome
            placeholderNome
            textSobrenome
            placeholderSobrenome
            textEmail
            placeholderEmail
            textCelular
            placeholderCelular
            paisBoolean
            textPais
            placeholderPais
            localizacaoBoolean
            textEstado
            placeholderEstado
            textCidade
            placeholderCidade
            areaAtuacaoBoolean
            textAreaDeAtuacao
            placeholderAreaDeAtuacao
            razaoContatoBoolean
            textRazaoDoContato
            placeholderRazaoDoContato
            descrevaSolicitacaoBoolean
            textDescrevaSolicitacao
            placeholderDescrevaSolicitacao
            pdfBoolean
            pdfDownload {
              handle
              width
              height
              url
            }
            textBotao
            textLoadingButton
            titleSuccess
            descriptionSuccess
          }
        }
        specialty {
          doctor {
            active
            id
            assignment
            crm
            employee
            id_api
            name
            phonecommercial
            state
            photo {
              handle
              height
              url
              width
            }
            specialties {
              id
              name
            }
          }
        }
      }
    }
  }
`;

export default MesesColoridos;
